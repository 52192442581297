@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-feature-settings: 'liga' 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html, body {
    @apply bg-lightgray;
  }

  body {
    @apply text-blue text-base;
  }

  /**
   * Remove up/down arrow of number input fields.
   */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  .h-24vh {
    height: 24vh;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    margin: 10px 10px 10px 10px;
    padding-right: 10px;
    margin-right: 200px;
    background-color: #3C506040;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #3c5060;
    border-radius: 10px;
  }
  
  .textarea-container {
    border-radius: 5px;
    border: 2px solid #3C506040;
    padding-right: 0.5rem;
  }
  
  .textarea-container:focus-within {
    border: 2px solid #3c5060;
  }

  .textarea-container textarea {
    outline: none;
    border: none;
  }
}
